import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Typography } from '@mui/material';

// Components
import TypeWriter from '../../components/TypeWriter';
import ImageList from '../../components/ImageList/ImageList';
import MicrophoneToggleButton from '../../components/MicrophoneToggleButton';

// Constants
import { API_ROUTES } from '../../constants/apiRoutes';
import {
  VOICE_COMMANDS,
  MESSAGES,
  RELOAD_ASSISTANT_PHRASES,
  START_ASSISTANT_PHRASES,
  VIDEO_VOLUME_STATUS,
  START_ASSISTANT_SIMILAR_PHRASES,
  SHAHEEN_AUDIO,
  SHAHEEN_SIMILAR_WORDS
} from '../../constants/common';
import { ROUTE_LIST } from '../../constants/routes';

// Redux
import { useAppSelector } from '../../redux/hook';

// Utils
import api from '../../utils/api';
import { checkSimilarWords, containsVideo, getVideoLabel } from '../../utils/helper';

// CSS
import './Dashboard.css';

// Images
import logo from '../../assets/images/shaheen-logo.svg';
import car from '../../assets/images/car.png';
import bg1 from '../../assets/images/bg-1.jpg';
import bg2 from '../../assets/images/bg-2.jpg';
import bg3 from '../../assets/images/bg-3.jpg';
import bg4 from '../../assets/images/bg-4.jpg';
import triangle from '../../assets/images/triangle.svg';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading } = useAppSelector((state) => state.apiStatus);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [utterance, setUtterance] = useState<SpeechSynthesisUtterance | null>(null);
  const [task, setTask] = useState('');
  const [questionAsked, setQuestionAsked] = useState('');
  const [isAssistantActive, setIsAssistantActive] = useState(false);
  const [silenceDuration, setSilenceDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isCommandOperation, setIsCommandOperation] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [videoLabel, setVideoLabel] = useState('');
  const [imageVideoURLS, setImageVideoURLS] = useState([]);
  const [volume, setVolume] = useState(1); // Initial volume set to maximum (1)
  const audioRef = useRef(new Audio(SHAHEEN_AUDIO));
  const videoRef = useRef<HTMLVideoElement>(null);
  const silenceThreshold = 3000; // 3 second silence threshold
  const resetTime = 10000; // 10 seconds
  const commands = [
    {
      command: [...START_ASSISTANT_PHRASES, ...START_ASSISTANT_SIMILAR_PHRASES],
      callback: (command: string, spokenPhrase: string, similarityMatch: number) => {
        if (
          (!isAssistantActive && similarityMatch > 0.5) ||
          (!isAssistantActive && checkSimilarWords(spokenPhrase, SHAHEEN_SIMILAR_WORDS))
        ) {
          SpeechRecognition.stopListening();
          setIsListening(false);
          setIsCommandOperation(true);
          setIsAssistantActive(true);
          resetTranscript();
          if (START_ASSISTANT_PHRASES.some((phrase) => command.toLowerCase().includes(phrase))) {
            setQuestionAsked(command);
          } else {
            setQuestionAsked(START_ASSISTANT_PHRASES[0]);
          }
          speakText(MESSAGES.HELP_TEXT);
          setTask(MESSAGES.HELP_TEXT);
        }
      },
      isFuzzyMatch: true,
      fuzzyMatchingThreshold: 0.5
    },
    {
      command: VOICE_COMMANDS.PLAY_BACKGROUND_MUSIC,
      callback: (command: string) => handleBackgroundAudio(true, command),
      isFuzzyMatch: true
    },
    {
      command: VOICE_COMMANDS.PAUSE_BACKGROUND_MUSIC,
      callback: (command: string) => handleBackgroundAudio(false, command),
      isFuzzyMatch: true
    },
    {
      command: VOICE_COMMANDS.PLAY_VIDEO,
      callback: (command: string) => handleVideoPlay(true, command),
      isFuzzyMatch: true,
      bestMatchOnly: true,
      matchInterim: true
    },
    {
      command: VOICE_COMMANDS.PAUSE_VIDEO,
      callback: (command: string) => handleVideoPlay(false, command),
      isFuzzyMatch: true,
      bestMatchOnly: true,
      matchInterim: true
    },
    {
      command: VOICE_COMMANDS.INCREASE_VIDEO_VOLUME,
      callback: (command: string) =>
        handleVideoVolume(VIDEO_VOLUME_STATUS.INCREASE_VOLUME, command),
      isFuzzyMatch: true,
      bestMatchOnly: true,
      matchInterim: true
    },
    {
      command: VOICE_COMMANDS.DECREASE_VIDEO_VOLUME,
      callback: (command: string) =>
        handleVideoVolume(VIDEO_VOLUME_STATUS.DECREASE_VOLUME, command),
      isFuzzyMatch: true,
      bestMatchOnly: true,
      matchInterim: true
    },
    {
      command: VOICE_COMMANDS.MUTE_VIDEO_VOLUME,
      callback: (command: string) => handleVideoVolume(VIDEO_VOLUME_STATUS.MUTE, command),
      isFuzzyMatch: true,
      bestMatchOnly: true,
      matchInterim: true,
      fuzzyMatchingThreshold: 1
    },
    {
      command: VOICE_COMMANDS.UNMUTE_VIDEO_VOLUME,
      callback: (command: string) => handleVideoVolume(VIDEO_VOLUME_STATUS.UNMUTE, command),
      isFuzzyMatch: true,
      bestMatchOnly: true,
      matchInterim: true,
      fuzzyMatchingThreshold: 1
    },
    {
      command: VOICE_COMMANDS.PLAY_GAME,
      callback: (command: string) => handleGamePlay(command),
      isFuzzyMatch: true,
      bestMatchOnly: true,
      matchInterim: true,
      fuzzyMatchingThreshold: 1
    },
    {
      command: VOICE_COMMANDS.RELOAD_ASSISTANT,
      callback: () => handleReloadAssistant(),
      bestMatchOnly: true
    }
  ];

  const { transcript, resetTranscript } = useSpeechRecognition({ commands });

  useEffect(() => {
    window.speechSynthesis.cancel();
    const newUtterance = new SpeechSynthesisUtterance();
    setUtterance(newUtterance);
    // Event listener for when speech starts
    newUtterance.onstart = () => {
      setCurrentTime(new Date().getTime());
      setIsSpeaking(true);
    };
    // Event listener for when speech ends
    newUtterance.onend = () => {
      SpeechRecognition.startListening({ continuous: true });
      setIsListening(true);
      setIsSpeaking(false);
      setCurrentTime(new Date().getTime());
    };
    // Event listener for speech synthesis errors
    newUtterance.onerror = () => {
      setIsSpeaking(false);
    };
    SpeechRecognition.startListening({ continuous: true });
    setIsListening(true);
    const intervalId = setInterval(() => {
      // Increment silence duration every second
      setSilenceDuration((prevDuration) => prevDuration + 1000);
    }, 1000);
    // Play background audio
    const audio = audioRef.current;
    audio.loop = true;
    audio.volume = 0.1;
    audio.play().catch((error) => {
      console.error('Failed to play background music:', error);
    });
    return () => {
      // Cleanup on component unmount
      clearInterval(intervalId);
      window.speechSynthesis.cancel();
      // Pause background audio
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };
  }, []);

  useEffect(() => {
    // Reset silence duration when speech is recognized
    if (transcript) {
      window.speechSynthesis.cancel();
      setCurrentTime(new Date().getTime());
      setTask('');
      setImageVideoURLS([]);
      setSilenceDuration(0);
      setIsCommandOperation(false);
    }
  }, [transcript]);

  useEffect(() => {
    // Reset  current time on API calls
    setCurrentTime(new Date().getTime());
  }, [isLoading]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          (questionAsked === MESSAGES.HELP_QUESTION &&
            RELOAD_ASSISTANT_PHRASES.some((phrase) => transcript.toLowerCase().includes(phrase))) ||
          (questionAsked === MESSAGES.HELP_QUESTION &&
            !isSpeaking &&
            new Date().getTime() - currentTime > resetTime)
        ) {
          handleReloadAssistant();
        } else if (
          VOICE_COMMANDS.PLAY_GAME.some((phrase) => questionAsked.toLowerCase().includes(phrase)) &&
          !isSpeaking &&
          silenceDuration >= silenceThreshold
        ) {
          navigate(ROUTE_LIST.PLAY_GAME);
        } else if (
          silenceDuration >= silenceThreshold &&
          transcript &&
          isAssistantActive &&
          !isLoading &&
          !isCommandOperation &&
          !isVideoPlaying
        ) {
          setIsVideoVisible(false);
          setQuestionAsked(transcript);
          const response = await api.post(API_ROUTES.OPEN_AI_CHAT, {
            question: transcript
          });
          const text = response.data.data.answer.content;
          const urls = response.data.data.answer.urls;
          const containsURL = containsVideo(urls);
          if (containsURL) {
            setIsVideoVisible(true);
            setIsVideoPlaying(true);
            setVideoLabel(getVideoLabel(urls[0]));
          } else {
            speakText(text);
            setTask(text);
            setIsVideoVisible(false);
          }
          setImageVideoURLS(urls);
          resetTranscript();
          setSilenceDuration(0);
        } else if (
          !isLoading &&
          !isSpeaking &&
          isAssistantActive &&
          transcript === '' &&
          new Date().getTime() - currentTime > resetTime &&
          !isVideoPlaying
        ) {
          // Ask Help Question
          SpeechRecognition.stopListening();
          setIsListening(false);
          resetTranscript();
          setQuestionAsked(MESSAGES.HELP_QUESTION);
          speakText(MESSAGES.HELP_QUESTION);
          setTask('');
          setImageVideoURLS([]);
          setIsVideoVisible(false);
        }
      } catch (error) {
        console.error('Error:', error);
        resetTranscript();
        setSilenceDuration(0);
      }
    };
    fetchData();
  }, [silenceDuration]);

  // Animation strat
  useEffect(() => {
    const handleAnimationIteration = () => {
      const landingWrap = document.querySelector('.landing-wrap') as HTMLElement;
      const logoWrap = document.querySelector('.logo-wrap') as HTMLElement;
      const carWrap = document.querySelector('.car-wrap') as HTMLElement;
      const textWrap = document.querySelector('.text-wrap') as HTMLElement;
      const slide = document.querySelector('.slide') as HTMLElement;
      const aboutWrap = document.querySelector('.about-wrap') as HTMLElement;
      const aboutListItems = document.querySelectorAll('.about-wrap li') as NodeListOf<HTMLElement>;

      if (!landingWrap.classList.contains('show-questions')) {
        logoWrap.style.animation = 'none';
        carWrap.style.animation = 'none';
        textWrap.style.animation = 'none';
        slide.style.animation = 'none';
        aboutWrap.style.animation = 'none';
        aboutListItems.forEach((item) => {
          item.style.animation = 'none';
        });

        void landingWrap.offsetWidth;

        logoWrap.style.animation = 'moveLogo 7s linear 2s forwards';
        carWrap.style.animation = 'moveCar2 8s ease-in-out 10s forwards';
        textWrap.style.animation = 'moveText2 9s ease-in-out 10s forwards';
        slide.style.animation = 'bgSlide 21s ease-in-out forwards';
        aboutWrap.style.animation = 'moveText 25s ease-in-out 20s forwards';
        let delay = 23;
        aboutListItems.forEach((item) => {
          item.style.animation = `fade 20s ease-in-out ${delay}s forwards`;
          delay += 0;
        });
      }
    };

    const intervalId = setInterval(handleAnimationIteration, 48000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (questionAsked !== '') {
      const landingWrap = document.querySelector('.landing-wrap') as HTMLElement;
      landingWrap.classList.add('show-questions');

      const queWrap = document.querySelector('.questions-wrap') as HTMLElement;
      queWrap.style.animation = 'showQuestions 2s linear 1s forwards';
    }
  }, [transcript]);
  // Animation end

  const speakText = (text: string) => {
    if (utterance) {
      SpeechRecognition.stopListening();
      setIsListening(false);
      utterance.text = text;
      window.speechSynthesis.cancel();
      window.speechSynthesis.speak(utterance);
    }
  };

  const handleBackgroundAudio = (play: boolean, command: string) => {
    if (isAssistantActive) {
      const audio = audioRef.current;
      audio.loop = true;
      audio.volume = 0.1;
      setIsCommandOperation(true);
      setQuestionAsked(command);
      if (play) {
        audio.play().catch((error) => {
          console.error('Failed to play background music:', error);
        });
        speakText(MESSAGES.PLAY_BACKGROUND_MUSIC);
        setTask(MESSAGES.PLAY_BACKGROUND_MUSIC);
      } else {
        audio.pause();
        audio.currentTime = 0;
        speakText(MESSAGES.PAUSE_BACKGROUND_MUSIC);
        setTask(MESSAGES.PAUSE_BACKGROUND_MUSIC);
      }
      resetTranscript();
    }
  };

  const handleVideoPlay = (playVideo: boolean, command: string) => {
    const video = videoRef.current;
    setIsCommandOperation(true);
    setQuestionAsked(command);
    if (isAssistantActive && video) {
      if (playVideo) {
        video.play();
        setIsVideoPlaying(true);
      } else {
        video.pause();
        setIsVideoPlaying(false);
      }
    }
    resetTranscript();
  };

  const handleVideoVolume = (volumeStatus: number, command: string) => {
    const video = videoRef.current;
    setIsCommandOperation(true);
    setQuestionAsked(command);
    if (isAssistantActive && video) {
      switch (volumeStatus) {
        case VIDEO_VOLUME_STATUS.MUTE:
          video.muted = true;
          break;
        case VIDEO_VOLUME_STATUS.UNMUTE:
          video.muted = false;
          break;
        case VIDEO_VOLUME_STATUS.INCREASE_VOLUME:
          {
            const incVolume = Math.min(volume + 0.1, 1);
            setVolume(incVolume);
            video.volume = incVolume;
          }
          break;
        case VIDEO_VOLUME_STATUS.DECREASE_VOLUME:
          {
            const decVolume = Math.max(volume - 0.1, 0);
            setVolume(decVolume);
            video.volume = decVolume;
          }
          break;
        default:
          break;
      }
    }
    resetTranscript();
  };

  const handleVideoEnded = () => {
    setIsVideoPlaying(false);
  };

  const handleGamePlay = (command: string) => {
    if (isAssistantActive) {
      speakText(MESSAGES.PLAY_GAME);
      setIsCommandOperation(true);
      setQuestionAsked(command);
      setTask(MESSAGES.PLAY_GAME);
      resetTranscript();
    }
  };

  const handleReloadAssistant = () => {
    window.location.reload();
  };

  return (
    <>
      <p>Test: {transcript}</p>
      <MicrophoneToggleButton listening={isListening} />
      <div className={questionAsked !== '' ? 'landing-wrap show-questions' : 'landing-wrap'}>
        <div className="content-wrap">
          <div className="logo-wrap">
            <img src={logo} alt="logo" />
            <h1>Your AI Assistant</h1>
          </div>
          <div className="car-wrap">
            <img src={car} alt="car" />
          </div>
          <div className="text-wrap">
            <span className="text">ask out loud!</span>
          </div>
          <div className="about-wrap">
            <h2>ask us about !</h2>
            <div className="about-inner">
              <ul>
                <li>
                  <div className="img-wrapper">
                    <img src={triangle} alt="about-inner-image" />
                  </div>
                  <h3>Who is Shaheen</h3>
                </li>
                <li>
                  <div className="img-wrapper">
                    <img src={triangle} alt="about-inner-image" />
                  </div>
                  <h3>Shaheen Game</h3>
                </li>
                <li>
                  <div className="img-wrapper">
                    <img src={triangle} alt="about-inner-image" />
                  </div>
                  <h3>Shaheen Show</h3>
                </li>
                <li>
                  <div className="img-wrapper">
                    <img src={triangle} alt="about-inner-image" />
                  </div>
                  <h3>Our Car</h3>
                </li>
                <li>
                  <div className="img-wrapper">
                    <img src={triangle} alt="about-inner-image" />
                  </div>
                  <h3>Our Accomplishments</h3>
                </li>
                <li>
                  <div className="img-wrapper">
                    <img src={triangle} alt="about-inner-image" />
                  </div>
                  <h3>Our Sponsors</h3>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="img-wrap">
          <ul className="slide">
            <li className="img-wrap1">
              <img src={bg1} alt="background-img" className="img-1" />
            </li>
            <li className="img-wrap2">
              <img src={bg2} alt="background-img" className="img-2" />
            </li>
            <li className="img-wrap3">
              <img src={bg3} alt="background-img" className="img-3" />
            </li>
            <li className="img-wrap4">
              <img src={bg4} alt="background-img" className="img-4" />
            </li>
          </ul>
        </div>
        {questionAsked || transcript ? (
          <div className="questions-wrap">
            <h2 className="question">Q. {transcript !== '' ? transcript : questionAsked}</h2>
            {isVideoVisible ? (
              <>
                <video
                  width="100%"
                  height="100%"
                  controls
                  ref={videoRef}
                  autoPlay
                  onEnded={handleVideoEnded}>
                  <source
                    src={imageVideoURLS.length > 0 ? imageVideoURLS[0] : ''}
                    type="video/mp4"
                  />
                </video>
                <Typography
                  variant="body2"
                  component="p"
                  className="video-label"
                  textAlign="center">
                  {videoLabel}
                </Typography>
              </>
            ) : (
              <>
                <TypeWriter text={task} speed={40} />
                <ImageList imageUrls={imageVideoURLS} />
              </>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Dashboard;
